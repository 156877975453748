import React from 'react';
import { BaseCard } from '@components/recently-viewed/card/BaseCard';
import { useAsync } from 'react-use';
import { ToursApi } from '@model/iceberg/service/tours';
import { TourDetails, Tours } from '@model/tours';
import { TourLink } from '@components/common/next-link/tour-link';
import { TripTypes } from '@model/common/tours/trip-types';

export interface ToursRecentlyViewedCardProps {
  id: string;
}

export const getTripType = (type: TripTypes) => {
  const mapping = {
    [TripTypes.ESCORTED_TOURS]: 'trip-types__escorted-tours--singular',
    [TripTypes.RAIL_JOURNEYS]: 'trip-types__rail-journeys--singular',
    [TripTypes.WALKING_ROUTES]: 'trip-types__walking-routes--singular',
    [TripTypes.MULTI_CENTRE]: 'trip-types__multicentres--singular',
    [TripTypes.FLY_DRIVES]: 'trip-types__fly-drives--singular',
    [TripTypes.RIVER_CRUISES]: 'trip-types__river-cruises--singular',
    [TripTypes.PRIVATE_TOURS]: 'trip-types__private-tours--singular',
    [TripTypes.CRUISES]: 'trip-types__cruises--singular',
    [TripTypes.SOLO_TOURS]: 'trip-types__solo-tours--singular',
    [TripTypes.ALL]: '',
    [TripTypes.HOLIDAYS]: ''
  };
  return mapping[type];
};

export const getSubtitle = (days: number, type: TripTypes, t: any) => {
  return `${days} ${t('common__label--day')} ${t(getTripType(type))}`;
};

export const ToursRecentlyViewedCard = ({ id }: ToursRecentlyViewedCardProps) => {
  const api: ToursApi = new ToursApi();

  if (!isNaN(Number(id))) {
    return null;
  }
  const response = useAsync(async () => await api.getTourDetails(id), []);

  if (response.loading || !response.value || !response.value.data) return null;
  const tours: Tours = response.value.data.tours;
  const tour: TourDetails = tours[0];
  if (tour) {
    const { path, image, tripType, name, subTitle } = tour;
    return (
      <TourLink path={path} tripType={tripType}>
        <BaseCard title={name} subtitle={subTitle} image={image} />
      </TourLink>
    );
  }
  return null;
};
